import { format, formatDistance } from 'date-fns'

/**
 * Utility functions, currently revolving around date display, and distance calculation
 * @author BrianCastor
 */
export function formatDatetimeAsDate(dateTimeStr) {
    let dt = new Date(dateTimeStr);
    return format(dt, 'MMM d, yyyy')
}

export function formatDatetimeAsDateAndTime(dateTimeStr) {
    let dt = new Date(dateTimeStr);
    return format(dt, 'MMM d, yyyy h:mm a')
}

export function formatHowLongAgoAsText(occuranceDate) {
    return formatDistance(new Date(occuranceDate), new Date());
}

export function getDistance(coords1, coords2) {
    //Returns the distance in miles between 2 coordinates **as the crow flies**
    if (!coords1 || !coords2) {
        return null;
    }
    if (!coords1.latitude || !coords1.longitude || !coords2.latitude || !coords2.longitude) {
        return null;
    }
    var radlat1 = Math.PI * coords1.latitude / 180
    var radlat2 = Math.PI * coords2.latitude / 180
    var theta = coords1.longitude - coords2.longitude
    var radtheta = Math.PI * theta / 180
    var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist)
    dist = dist * 180 / Math.PI
    dist = dist * 60 * 1.1515
    dist = dist * 0.8684
    return Math.round(dist * 10) / 10
}

export function convertDelimitedStringToArray(stringToParse, delimiter = ";") {
    if (!stringToParse) {
        return [];
    }
    if (!Array.isArray(stringToParse)) {
        return stringToParse.split(delimiter).map(item => {
            return item.trim();
        });
    }
    else {
        return stringToParse;
    }
}

export function getTypeOfSalesforceRecord(recordData) {
    return recordData.attributes.type;
}
